body {
  margin: 0;
  font-family: Gabarito, sans-serif, Geneva, Tahoma, sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Oxygen", "Ubuntu",
    "Nunito Sans", "Fira Sans", "Helvetica Neue", "Droid Sans", "Cantarell",
    "Segoe UI", "Roboto", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
